import { Center, Spinner, Text } from '@chakra-ui/react'

import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router'
import { ProfileContext, UseProfileArgs, useProfileData } from '../../../contexts/ProfileContext'
import { ScreenContext } from '../../../contexts/ScreenContext'
import { ThemeContext } from '../../../contexts/ThemeContext'
import { useAuth } from '../../../store/auth'
import { DefaultModal } from '../../Modals/DefaultModal'
import { ProfileBody as ProfileBodyV1 } from './Body/V1/ProfileBody'
import { ProfileBody as ProfileBodyV2 } from './Body/V2/ProfileBody'
import { PaymentsDueModal } from './Payments/PaymentsDue/PaymentsDueModal'
import { PaymentsProvider } from './Payments/PaymentsProvider'
import { ProfileVersion, UserProfileProps } from './types'
import { UrgentAlertsPopUp } from './UrgentAlertsPopUp'

export const PatientProfile: React.FC<UserProfileProps> = ({
  basePath,
  // tab,
  // subTab,
  inList,
  // onTabSelect,
  // onClose,
}) => {
  const [profileVersion] = useState<ProfileVersion>('v1')
  const me = useAuth(s => s.authUser)
  const navigate = useNavigate()

  const useProfileArgs = useMemo<UseProfileArgs>(
    () => ({
      profileVersion,
      basePath,
    }),
    [profileVersion, basePath],
  )
  const userData = useProfileData(useProfileArgs)
  const { height: screenHeight, isMobile, width: screenWidth } = useContext(ScreenContext)

  const height = useMemo(() => screenHeight - (isMobile ? 10 : 20), [screenHeight, isMobile])
  const width = useMemo(() => screenWidth - (isMobile ? 10 : 20), [screenWidth, isMobile])

  const { user, selectedAssessment, loading, baseItem, baseItemType } = userData
  const { loading: assessmentLoading } = selectedAssessment ?? {}
  const { reverseName } = user ?? {}
  useEffect(() => {
    if (reverseName) document.title = `${reverseName} - HB`
    return () => {
      document.title = 'Hamilton Billing'
    }
  }, [reverseName])

  const onClose = useCallback(() => {
    if (inList && baseItemType)
      navigate(basePath && basePath !== '/' ? `${basePath}/${baseItemType}` : `/${baseItemType}`)
    else navigate(basePath || '/')
  }, [baseItemType, basePath, inList, navigate])

  const ProfileBody = profileVersion === 'v2' ? ProfileBodyV2 : ProfileBodyV1
  return (
    <ProfileContext.Provider value={userData}>
      <ThemeContext.Provider value={{ hideLabels: true, placeholderAbove: true }}>
        <PaymentsProvider>
          <DefaultModal
            isOpen={!!baseItem}
            closeOnOverlayClick={false}
            overlayHeader
            render={() => (
              <>
                {user && !loading && me ? (
                  <ProfileBody height={height} width={width} />
                ) : (
                  // </Stack>
                  <Center color="gray.600" px={2} py={4} bg="#FFC7BC">
                    <Spinner mr={2} />
                    <Text>Loading...</Text>
                  </Center>
                )}
                <PaymentsDueModal />
                {assessmentLoading || loading ? null : <UrgentAlertsPopUp />}
              </>
            )}
            size="6xl"
            onClose={onClose}
            contentProps={{
              // m: '50px',
              padding: 0,
              maxH: height,
              height,
              maxWidth: '100%',
              width,
              bg: '#efefef',
              overflow: 'hidden',
            }}
            bodyProps={{
              height,
              width,
              padding: 0,
              maxHeight: height,
              overflow: 'hidden',
              display: 'flex',
              flexFlow: 'column',
              overflowY: isMobile ? 'auto' : 'hidden',
            }}
          />
        </PaymentsProvider>
      </ThemeContext.Provider>
    </ProfileContext.Provider>
  )
}
