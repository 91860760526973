import { CalendarIcon, RepeatIcon } from '@chakra-ui/icons'
import {
  Box,
  BoxProps,
  Flex,
  FlexProps,
  HStack,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  StackProps,
  Text,
} from '@chakra-ui/react'
import {
  AdminAssessmentData,
  AppName,
  ASSESSMENT_SNIPPETS,
  ASSESSMENTS_ADMIN,
  AssessmentSnippet,
  Claim,
  colors,
  FieldTypes,
  getCoverageLabel,
  getCoverageText,
  getDateString,
  getPlanNextActionPath,
  InsuranceCoverage,
  insurersCollection,
  ListItem,
  ListNextActionEntity,
  NextAction,
  parseNextActionDate,
  PatientCoverageId,
  PopulatedAssessment,
  TextAreaField,
  UpdateCallback,
  UpdateNextActionArgs,
} from '@hb/shared'

import { deleteField, doc, DocumentReference, updateDoc, writeBatch } from 'firebase/firestore'
import { httpsCallable } from 'firebase/functions'
import React, { FC, useCallback, useContext, useMemo, useState } from 'react'
import { db, functions } from '../../../backend'
import { PopUpMessageContext, useApp } from '../../../contexts'
import { useCollectionItem } from '../../../hooks/backend/useCollectionItem'
import { useMe } from '../../../hooks/backend/useMe'
import { addMetadata } from '../../../utils'
import { Editable } from '../../forms/Input/index'

const nextActionField: TextAreaField = {
  type: FieldTypes.TEXTAREA,
  placeholder: 'Next action',
  optional: true,
}

const NextActionInput = ({
  nextAction,
  onSubmit,
  flexProps,
  dataCellProps,
  // dateContainerProps,
}: {
  nextAction: NextAction | undefined | null
  onSubmit: (updated: string) => Promise<UpdateCallback>
  flexProps?: FlexProps
  dataCellProps?: BoxProps
  // dateContainerProps?: StackProps
}) => {
  // const [isEditing, setIsEditing] = useState(false)
  const text = useMemo(
    () => (nextAction?.text && nextAction.text !== 'zzz' ? nextAction.text : ''),
    [nextAction],
  )
  return (
    <Flex align="flex-start" minW="0" flex={1} {...flexProps}>
      <Editable
        onSubmit={onSubmit}
        value={text}
        editableStackProps={{ bg: 'transparent', boxShadow: 'none' }}
        // openCallback={() => {
        //   setIsEditing(true)
        // }}
        // closeCallback={() => {
        //   setIsEditing(false)
        // }}
        dataCellProps={{
          fontSize: 'sm',
          borderRadius: '4px',
          border: '1px solid #cdcdcd',
          background: 'white',
          px: 2,
          ...dataCellProps,
        }}
        inputStyle={{
          padding: '0.4rem 0.5rem',
        }}
        style={{
          flex: 1,
          minWidth: 0,
        }}
        field={nextActionField}
      />
    </Flex>
  )
}

const updateNextActionDefault = async (
  appName: AppName,
  collection: string,
  id: string,
  updatedText: string | null,
  isNew: boolean,
  propPath?: string,
) => {
  const ref = doc(db, collection, id)
  const updatedNextAction = updatedText
    ? addMetadata({ text: updatedText, updatedOn: Date.now() }, appName, isNew)
    : null

  const promise = propPath
    ? updateDoc(ref, propPath, updatedNextAction)
    : updateDoc(ref, {
        nextActionText: updatedText,
        nextActionDate: parseNextActionDate(updatedText ?? ''),
        nextAction: updatedNextAction,
      })
  return promise
    .then(() => ({ success: 'Next action updated!' }))
    .catch((err: any) => {
      console.error(err)
      return { error: 'Internal error' }
    })
}

const updateNextActionFunction = async (
  appName: AppName,
  collection: string,
  id: string,
  updatedText: string | null,
  propPath?: string,
): Promise<UpdateCallback> => {
  const fbFunc = httpsCallable<UpdateNextActionArgs>(functions, 'updateNextAction')

  return fbFunc({ collection, id, updatedText, appName, propPath })
    .then(() => ({ success: 'Next action updated!' }))
    .catch((err: any) => {
      console.error(err)
      return { error: 'Internal error' }
    })
}

const updateNextAction = async (
  appName: AppName,
  collection: string,
  id: string | undefined,
  updatedText: string | null,
  isNew: boolean,
  propPath?: string,
): Promise<UpdateCallback> => {
  if (!id) return { error: 'No id provided' }
  switch (collection) {
    case 'visits':
      return updateNextActionFunction(appName, collection, id, updatedText, propPath)
    default:
      return updateNextActionDefault(appName, collection, id, updatedText, isNew, propPath)
  }
}

export const CollectionNextActionContent: FC<{
  collection: string
  id?: string
  item?: ListItem
  inList: boolean
}> = ({ item, collection, id }) => {
  const { nextAction, nextActionText, nextActionDate } =
    (item as {
      nextAction?: NextAction
      nextActionText?: string
      nextActionDate?: number
    }) ?? {}
  const displayedNextAction = useMemo<NextAction>(
    () =>
      nextAction ?? {
        text: nextActionText || '',
        date: nextActionDate || 0,
      },
    [nextAction, nextActionText, nextActionDate],
  )

  // const { refetchItem } = useDataListContext()
  const { appName } = useApp()
  const handleSubmit = useCallback(
    async (updatedText: string | null): Promise<UpdateCallback> => {
      await updateNextAction(appName, collection, id, updatedText, !nextAction)
      return { success: 'Next action updated!' }
    },
    [collection, id, appName, nextAction],
  )
  return <NextActionInput nextAction={displayedNextAction} onSubmit={handleSubmit} />
}

export const AssessmentNextAction = ({
  item,
  collection,
  id,
  inList,
  ...props
}: FlexProps & { item?: ListItem; collection: string; inList: boolean }) => (
  <Flex width="100%" px={1} bg={`${colors.pink.hex}99`} align="center" {...props}>
    <Text
      whiteSpace="nowrap"
      fontSize="sm"
      position="relative"
      px={2}
      color="gray.500"
      fontWeight={600}>
      Next Action:
    </Text>
    <CollectionNextActionContent inList={inList} id={id} collection={collection} item={item} />
  </Flex>
)

export const useGetNextAction = () => {
  const me = useMe()
  return useCallback(
    (text: string) => {
      if (!me) throw new Error('Not logged in')
      return {
        text,
        updatedOn: Date.now(),
        updatedBy: me.uid,
      }
    },
    [me],
  )
}

// TODO: update this to use pregnancy next actions
export const useSubmitNextAction = (
  assessmentId: string | null | undefined,
  nextActionPath: string | null | undefined,
  inList: boolean,
) => {
  const getNextAction = useGetNextAction()
  const { showError } = useContext(PopUpMessageContext)
  return useCallback(
    async (updated: string): Promise<UpdateCallback> => {
      if (!assessmentId || !nextActionPath) {
        return { error: 'No assessmentId or nextActionPath provided' }
      }

      try {
        const batch = writeBatch(db)
        const submitted = updated ? getNextAction(updated) : deleteField()

        const assessmentAdminRef = doc(
          db,
          ASSESSMENTS_ADMIN,
          assessmentId,
        ) as DocumentReference<AdminAssessmentData>

        const assessmentSnippetRef = doc(
          db,
          ASSESSMENT_SNIPPETS,
          assessmentId,
        ) as DocumentReference<AssessmentSnippet>
        batch.update(assessmentAdminRef, nextActionPath, submitted)
        if (inList) batch.update(assessmentSnippetRef, nextActionPath, submitted)
        await batch.commit()
        return { success: 'Next action updated!' }
      } catch (err: any) {
        console.error(err)
        showError('Error updating next action')
        return { error: 'Error updating next action' }
      }
    },
    [nextActionPath, getNextAction, assessmentId, inList, showError],
  )
}

export const useSubmitCoverageNextAction = (
  assessmentId: string | null | undefined,
  id: PatientCoverageId,
  inList: boolean,
) => {
  const assessmentPath = useMemo(() => getPlanNextActionPath(id), [id])
  return useSubmitNextAction(assessmentId, assessmentPath, inList)
}

// export const useSubmitCoverageNextActionFromId = (
//   patientId: string,
//   assessmentId: string | null | undefined,
//   id: InsuranceCoverageId,
// ) => useSubmitCoverageNextAction(patientId, assessmentId, id)

export const NextActionHeader = ({
  label,
  nextAction,
  dateContainerProps,
}: {
  label?: string
  nextAction: NextAction | undefined | null
  dateContainerProps?: StackProps
}) => (
  <Flex px={1} w="100%" align="center">
    {label ? (
      <Text
        flex={1}
        lineHeight={1}
        isTruncated
        minW="0"
        color="#777"
        whiteSpace="pre"
        fontSize="0.8rem"
        fontWeight={600}>
        {label}
      </Text>
    ) : null}
    {nextAction?.updatedOn ? (
      <HStack px={1} spacing={1} {...dateContainerProps}>
        <CalendarIcon width={3} color="gray.600" />
        <Text fontWeight={500} color="gray.600" fontSize="xs">
          {getDateString(nextAction.updatedOn, 'short', false)}
        </Text>
      </HStack>
    ) : null}
  </Flex>
)

const BackupNextActionButton = ({
  backupNextAction,
  onSwapBackup,
}: {
  backupNextAction: NextAction
  onSwapBackup: () => Promise<void>
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const handleSwap = useCallback(async () => {
    setIsLoading(true)
    await onSwapBackup()
    setIsLoading(false)
  }, [onSwapBackup])
  return (
    <Popover placement="left" trigger="hover">
      <PopoverTrigger>
        <IconButton
          isLoading={isLoading}
          onClick={handleSwap}
          variant="ghost"
          size="xs"
          icon={<RepeatIcon />}
          aria-label="Backup Next Action"
        />
      </PopoverTrigger>
      <Portal>
        <PopoverContent>
          <PopoverBody bg="gray.50">
            <Flex direction="column" gap={1}>
              <Text fontSize="sm" fontWeight={600} color="gray.700">
                Backup Next Action
              </Text>
              <NextActionPreview nextAction={backupNextAction} />
            </Flex>
          </PopoverBody>
          <PopoverArrow bg="gray.50" />
        </PopoverContent>
      </Portal>
    </Popover>
  )
}

export const NextActionContent = ({
  label,
  nextAction,
  dataCellProps,
  backupNextAction,
  dateContainerProps,
  handleSubmit,
  flexProps,
  onSwapBackup,
}: {
  label?: string
  nextAction: NextAction | undefined | null
  backupNextAction?: NextAction | undefined | null
  dataCellProps?: BoxProps
  dateContainerProps?: StackProps
  flexProps?: FlexProps
  handleSubmit: (updated: string) => Promise<UpdateCallback>
  onSwapBackup?: () => Promise<void>
}) => {
  const fProps = useMemo(() => ({ align: 'center', ...flexProps }), [flexProps])
  return (
    <Flex
      bg="whiteAlpha.600"
      borderTop="1px solid #00000033"
      w="100%"
      direction="column"
      // pb={1}
      // pt={2}
      py={1}
      px={2}>
      <NextActionHeader
        label={label}
        nextAction={nextAction}
        dateContainerProps={dateContainerProps}
      />
      <Flex align="center" gap={1} w="100%">
        <Box flex={1} minW="0">
          <NextActionInput
            dataCellProps={dataCellProps}
            flexProps={fProps}
            nextAction={nextAction}
            onSubmit={handleSubmit}
            // dateContainerProps={dateContainerProps}
          />
        </Box>
        {backupNextAction && backupNextAction.text !== nextAction?.text && onSwapBackup ? (
          <BackupNextActionButton onSwapBackup={onSwapBackup} backupNextAction={backupNextAction} />
        ) : null}
      </Flex>
    </Flex>
  )
}
const useEntityNextActionPaths = (entity: ListNextActionEntity) => {
  switch (entity.type) {
    case 'gyn':
      return { assessmentPath: 'gynNextAction' }
    case 'coverage':
      return {
        assessmentPath: getPlanNextActionPath(entity.id),
      }
  }
}

const getCoverageLabelPrefix = (id: PatientCoverageId) => {
  if (id === 'primary') return 'Primary'
  if (id === 'secondary') return 'Secondary'
  return 'Potential Plan'
}

const getCoverageNextActionLabel = (
  id: PatientCoverageId,
  coverage: InsuranceCoverage | null | undefined,
) => {
  const prefix = getCoverageLabelPrefix(id)
  return `${prefix.toUpperCase()} - ${coverage?.insuranceProvider?.name || 'No Insurer'}`
}

const useEntityNextActionLabel = (entity: ListNextActionEntity) => {
  switch (entity.type) {
    case 'gyn':
      return 'Gyn Next Action'
    case 'coverage':
      return getCoverageNextActionLabel(entity.id, entity.coverage)
  }
}

interface EntityNextActionContentProps {
  assessmentId: string | null
  entity: ListNextActionEntity
  nextAction: NextAction | undefined | null
  backupNextAction?: NextAction | undefined | null
  onSwapBackup?: () => Promise<void>
  inList: boolean
  flexProps?: FlexProps
  dataCellProps?: BoxProps
  dateContainerProps?: StackProps
}
export const EntityNextActionContent = ({
  entity,
  nextAction,
  assessmentId,
  inList,
  flexProps,
  backupNextAction,
  dataCellProps,
  onSwapBackup,
  dateContainerProps,
}: EntityNextActionContentProps) => {
  const { assessmentPath } = useEntityNextActionPaths(entity)
  const label = useEntityNextActionLabel(entity)
  const handleSubmit = useSubmitNextAction(assessmentId, assessmentPath, inList)
  return (
    <NextActionContent
      dataCellProps={dataCellProps}
      dateContainerProps={dateContainerProps}
      flexProps={flexProps}
      label={label}
      nextAction={nextAction}
      backupNextAction={backupNextAction}
      onSwapBackup={onSwapBackup}
      handleSubmit={handleSubmit}
    />
  )
}

export const CoverageNextActionContent = ({
  assessmentId,
  id,
  inList,
  assessment,
  coverage,
  nextAction,
  backupNextAction,
  onSwapBackup,
}: {
  assessmentId: string
  id: PatientCoverageId
  assessment: PopulatedAssessment | null
  coverage: InsuranceCoverage | undefined | null
  inList: boolean
  nextAction: NextAction | undefined | null
  backupNextAction?: NextAction | undefined | null
  onSwapBackup?: () => Promise<void>
}) => {
  const nextActionPath = useMemo(() => getPlanNextActionPath(id), [id])
  const handleSubmit = useSubmitNextAction(assessmentId, nextActionPath, inList)
  const labelPrefix = getCoverageLabel(id, assessment)
  const { item: insuranceProvider } = useCollectionItem(
    insurersCollection,
    coverage?.insuranceProviderId,
  )

  const coverageText = getCoverageText(coverage, insuranceProvider)
  const label = useMemo(
    () => `${labelPrefix.toUpperCase()} - ${coverageText}`,
    [labelPrefix, coverageText],
  )
  return (
    <NextActionContent
      label={label}
      nextAction={nextAction}
      handleSubmit={handleSubmit}
      backupNextAction={backupNextAction}
      onSwapBackup={onSwapBackup}
    />
  )
}

const getServiceType = (claim: Claim) => claim?.serviceType ?? claim?.legacy?.serviceType
export const ClaimNextAction = ({
  claimId,
  claim,
  inList,
  ...props
}: FlexProps & {
  claim: Claim
  claimId: string
  inList: boolean
}) => {
  const label = useMemo(
    () => getServiceType(claim)?.toString().toUpperCase() || 'NO SERVICE TYPE',
    [claim],
  )
  return (
    <Flex flexFlow="column" bg="#efefef" align="flex-start" py={1} w="100%" {...props}>
      {/* <Text px={2} pt={1} color="#777" whiteSpace="pre" fontSize="0.8rem" fontWeight={600}>
        {getServiceType(claim)?.toString().toUpperCase() || 'NO SERVICE TYPE'}
      </Text> */}
      <NextActionHeader label={label} nextAction={claim.nextAction} />
      <Flex w="100%">
        <CollectionNextActionContent
          inList={inList}
          id={claimId}
          item={claim}
          collection="claims"
        />
      </Flex>
    </Flex>
  )
}

export const GynNextAction = ({
  nextAction,
  assessmentId,
  inList,
}: {
  nextAction: NextAction | undefined | null
  assessmentId: string | null | undefined
  inList: boolean
}) => {
  const handleSubmit = useSubmitNextAction(assessmentId, 'gynNextAction', inList)
  return (
    <Flex flexFlow="column" borderTop="1px solid #cdcdcd" bg="white" w="100%" px={2} py={1}>
      <Text fontWeight={600} color="#777" w="100%" px={2} py={1}>
        Gyn Next Action
      </Text>
      <NextActionInput nextAction={nextAction} onSubmit={handleSubmit} />
    </Flex>
  )
}

export const NextActionPreview = ({ nextAction }: { nextAction: NextAction }) => (
  <Flex w="100%" align="center" bg="white" boxShadow="md">
    <Flex minW="0" flex={1}>
      <Text fontSize="sm" color="gray.700" px={2} py={1} whiteSpace="pre-wrap" maxW="100%">
        {nextAction.text}
      </Text>
    </Flex>
    {nextAction.updatedOn ? (
      <Text fontSize="xs" fontWeight={600} color="gray.400" px={2} py={1}>
        {getDateString(nextAction.updatedOn, 'short', false)}
      </Text>
    ) : null}
  </Flex>
)
