import { Box, Center, CircularProgress, HStack, Text, VStack } from '@chakra-ui/react'
import {
  colors,
  DropdownField,
  FieldTypes,
  formatPhoneNumber,
  getFullName,
  PopulatedAssessment,
  User,
} from '@hb/shared'

import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { InlineWidget } from 'react-calendly'
import { UserAssessmentsContext } from '../../contexts'
import { FormsViewContext } from '../../contexts/FormsViewContext'
import { fetchCalendlyLink, fetchCalendlyLinks, usePopulatedUser } from '../../hooks'
import { useAppModals, useAuth } from '../../store'
import { StandaloneInput } from '../forms'
import { InputRef } from '../forms/Input/types'
import { DefaultModal } from './DefaultModal'

const bcbsAnthemId = 'p5ZP4GqqsBiynCfwqItL'
// const standardCalendlyLink = 'https://calendly.com/billerhbb3'
const medicaidCalendlyLink = 'https://calendly.com/Sheenahb'
const bcbsCalendlyLink = 'https://calendly.com/sarahhamiltonbilling'
const getCalendlyLink = async (
  user: User | null,
  assessment?: PopulatedAssessment | null,
): Promise<string | null> => {
  const { mergedData, sentBy } = assessment ?? {}
  // get link for assessment sender if applicable
  let sentByCalendlyLink: string | null = null
  if (sentBy) {
    await fetchCalendlyLink(sentBy)
      .then(link => {
        sentByCalendlyLink = link
      })
      .catch(err => {
        console.error(err)
      })
  }
  if (sentByCalendlyLink) return sentByCalendlyLink

  const insuranceInfo = mergedData?.['insurance-info'] ?? {}
  const { option, onePlanIsMedicaid, medicaidCoverage } = insuranceInfo ?? {}
  if (option === 'medicaid') return medicaidCalendlyLink
  if (option === 'two-plans' && onePlanIsMedicaid && medicaidCoverage?.currentlyOnMedicaidPlan) {
    return medicaidCalendlyLink
  }
  const insuranceProviderId = user?.insurancePlans?.primary?.insuranceProviderId
  if (insuranceProviderId === bcbsAnthemId) return bcbsCalendlyLink

  return null
}

// const medicaidNonMedicaidCalendlyLinks = [
//   {
//     calendlyLink: 'https://calendly.com/billerhbb3',
//     calendlyDescription: 'Non-Medicaid Plans',
//   },
//   {
//     calendlyLink: 'https://calendly.com/Sheenahb',
//     calendlyDescription: 'Medicaid Plans',
//   },
// ]

// const medicaidNonMedicaidCalendlyField: DropdownField = {
//   type: FieldTypes.DROPDOWN,
//   placeholder: 'Plan type',
//   optional: true,
//   options: medicaidNonMedicaidCalendlyLinks.map((link) => ({
//     id: link.calendlyLink,
//     text: link.calendlyDescription,
//   })),
// }

const SelectCalendlyLink = ({
  calendlyLinks,
  setManualCalendlyLink,
  manualCalendlyLink,
  autoCalendlyLink,
}: {
  calendlyLinks: Array<{
    calendlyLink: string
    calendlyDescription: string
  }> | null
  manualCalendlyLink: string | null
  setManualCalendlyLink: (link: string) => void
  autoCalendlyLink: string | null
}) => {
  const selectCalendlyLinkField = useMemo<DropdownField>(
    () => ({
      type: FieldTypes.DROPDOWN,
      placeholder: 'Calendly Link',
      optional: true,
      options: (calendlyLinks && Array.isArray(calendlyLinks) ? calendlyLinks : []).map(link => ({
        id: link.calendlyLink,
        text: link.calendlyDescription,
      })),
    }),
    [calendlyLinks],
  )
  const inputRef = useRef<InputRef>(null)
  useEffect(() => {
    if (!manualCalendlyLink && !autoCalendlyLink && calendlyLinks) {
      setTimeout(() => {
        inputRef.current?.focus()
      }, 100)
    }
  }, [manualCalendlyLink, autoCalendlyLink, calendlyLinks])

  return (
    <HStack justify="center" w="100%" minW="300px">
      {calendlyLinks ? (
        <StandaloneInput
          theme="detailed"
          style={{ width: '100%' }}
          onChange={v => {
            setManualCalendlyLink(v)
          }}
          ref={inputRef}
          field={selectCalendlyLinkField}
          value={manualCalendlyLink ?? autoCalendlyLink}
        />
      ) : (
        <HStack
          bg="white"
          px={3}
          py={2}
          w="100%"
          justify="center"
          border="1px solid #cdcdcd"
          borderRadius={6}>
          <CircularProgress size={6} color={colors.pink.hex} isIndeterminate />
          <Text color="gray.600">Loading calendars...</Text>
        </HStack>
      )}
    </HStack>
  )
}

const ScheduleCallBody = () => {
  const user = useAuth(s => s.user)
  const scheduleCall = useAppModals(s => s.scheduleCall)
  const {
    assessment: { populated: formsViewAssessment },
  } = useContext(FormsViewContext)
  const { mostRecent: mostRecentAssessment } = useContext(UserAssessmentsContext)
  const [autoCalendlyLink, setCalendlyLink] = useState<string | null>(null)
  const assessment = useMemo(
    () => formsViewAssessment ?? mostRecentAssessment,
    [formsViewAssessment, mostRecentAssessment],
  )
  const [manualCalendlyLink, setManualCalendlyLink] = useState<string | null>(null)
  const [calendlyLinks, setCalendlyLinks] = useState<Array<{
    calendlyLink: string
    calendlyDescription: string
  }> | null>(null)

  // const [fetchingLinks, setFetchingLinks] = React.useState(false)
  const [fetchingAutoLink, setFetchingAutoLink] = useState(true)

  useEffect(() => {
    // setFetchingLinks(true)
    fetchCalendlyLinks().then(links => {
      // setFetchingLinks(false)
      setCalendlyLinks(links)
    })
  }, [])

  useEffect(() => {
    setFetchingAutoLink(true)
    getCalendlyLink(user, assessment)
      .then(link => {
        setFetchingAutoLink(false)
        if (link) {
          setCalendlyLink(link)
        }
      })
      .catch(err => {
        console.error(err)
        setFetchingAutoLink(false)
      })
  }, [user, assessment, scheduleCall])

  const { sentOn } = assessment ?? {}
  const { user: baseUser } = useAuth()
  const { user: populatedUser } = usePopulatedUser(baseUser?.id)
  const prefill = useMemo(
    () => ({
      name: getFullName(populatedUser),
      email: populatedUser?.email ?? '',
      firstName: populatedUser?.fname ?? '',
      lastName: populatedUser?.lname ?? '',
      customAnswers: {
        a1: formatPhoneNumber(populatedUser?.phone),
        a2: assessment?.midwife?.name ?? '',
        a3: populatedUser?.insurancePlans?.primary?.insuranceProvider?.name ?? '',
      },
    }),
    [populatedUser, assessment],
  )
  const usedLink = useMemo(
    () => manualCalendlyLink ?? autoCalendlyLink,
    [manualCalendlyLink, autoCalendlyLink],
  )

  if (!sentOn && !manualCalendlyLink && !autoCalendlyLink) {
    return (
      <Center w="100%" p={4}>
        <VStack
          bg="white"
          boxShadow="md"
          w="100%"
          align="center"
          py={4}
          px={2}
          borderRadius={6}
          spacing={2}>
          <Text fontFamily="hero-new">Who would you like to schedule a call with?</Text>
          <SelectCalendlyLink
            calendlyLinks={calendlyLinks}
            setManualCalendlyLink={setManualCalendlyLink}
            manualCalendlyLink={manualCalendlyLink}
            autoCalendlyLink={null}
          />
        </VStack>
      </Center>
    )
  }
  return !fetchingAutoLink ? (
    <Center h="100%" flexFlow="column" position="relative" w="100%">
      {fetchingAutoLink ? null : (
        <Box
          zIndex={2}
          w="100%"
          py={1}
          px={3}
          transition="height 400ms"
          borderBottom="1px solid #cdcdcd"
          bg="gray.50">
          <SelectCalendlyLink
            autoCalendlyLink={autoCalendlyLink}
            calendlyLinks={calendlyLinks}
            manualCalendlyLink={manualCalendlyLink}
            setManualCalendlyLink={setManualCalendlyLink}
          />
        </Box>
      )}
      <Box flex={1} zIndex={1} pos="relative" w="100%">
        <Box p={2} position="relative" h="100%" w="100%" zIndex={2}>
          {usedLink ? (
            <InlineWidget
              prefill={prefill}
              styles={{ height: '100%', width: '100%', zIndex: 2 }}
              LoadingSpinner={() => (
                <Box top={4} position="absolute" w="100%">
                  <Center w="100%" minH="100px" pointerEvents="none" gap={2}>
                    <CircularProgress isIndeterminate color={colors.pink.hex} size={6} />
                    <Text color="gray.500" fontSize="sm">
                      Loading Calendly Widget...
                    </Text>
                  </Center>
                </Box>
              )}
              url={usedLink}
            />
          ) : null}
        </Box>
      </Box>
    </Center>
  ) : (
    <Center w="100%" p={2}>
      <HStack boxShadow="md" borderRadius={4} p={4} bg="whitesmoke">
        <CircularProgress size={6} color={colors.green.hex} isIndeterminate />
        <Text color="gray.600">Loading calendly link...</Text>
      </HStack>
    </Center>
  )
}

const ScheduleCallHeader = () => (
  <HStack bg={colors.green.hex} w="100%" pl={3} pr={12}>
    <Text color="white" fontSize="lg" fontWeight="bold" fontFamily="Comfortaa">
      Schedule Call
    </Text>
  </HStack>
)

export const ScheduleCallModal = ({ id = 'root' }: { id?: string }) => {
  const scheduleCall = useAppModals(s => s.scheduleCall)

  return scheduleCall.openId === id ? (
    <DefaultModal
      isCentered
      size="xl"
      isOpen
      header={<ScheduleCallHeader />}
      contentProps={{
        p: 0,
        bg: 'gray.100',
        overflowY: 'hidden',
        overflowX: 'hidden',
        maxHeight: '100vh',
        height: '800px',
      }}
      bodyProps={{
        borderTop: '1px solid #cdcdcd',
        bg: 'gray.100',
        overflowY: 'hidden',
        overflowX: 'hidden',
      }}
      render={() => <ScheduleCallBody />}
      onClose={scheduleCall.close}
    />
  ) : null
}
