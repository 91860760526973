import {
  getInvoiceScheduleDate,
  InvoiceAssessmentPaymentsSnippet,
  INVOICES,
  PracticeInvoice,
  UseQuery,
  WithId,
} from '@hb/shared'
import { collection, doc, getDoc } from 'firebase/firestore'
import { db } from '../../backend/db'

const invoicesCollection = collection(db, INVOICES)
const getInvoiceDoc = (invoiceId: string) => doc(invoicesCollection, invoiceId)
export const getInvoice = (invoiceId: string): Promise<PracticeInvoice> =>
  getDoc(getInvoiceDoc(invoiceId)).then(snapshot => snapshot.data() as PracticeInvoice)

export const getDisplayedPregnancies = (
  assessmentSnippets: WithId<InvoiceAssessmentPaymentsSnippet>[],
) => assessmentSnippets.filter(a => Math.abs(a.practiceFee) >= 0.01)

export const getInvoicesForMonth = (
  data: UseQuery<PracticeInvoice>,
  year: number,
  month: number,
): WithId<PracticeInvoice>[] => {
  const { data: invoices } = data
  // return invoices?.filter(invoice => invoice.scheduledForShort === `${year}-${month}-01`)
  const scheduledFor = getInvoiceScheduleDate({ year, month }).split('T')[0]
  return Object.entries(invoices || {})
    .filter(([_, invoice]) => invoice.scheduledForShort === scheduledFor)
    .map(([id, invoice]) => ({ id, ...invoice }))
    .sort((a, b) => (a.id < b.id ? -1 : 1))
}
