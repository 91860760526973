// check if search is valid email, phone, dob

import {
  AnyObject,
  AssessmentInterface,
  BaseQuery,
  ClaimInterface,
  CollectionFilter,
  defaultEmailValidation,
  isValidDate,
  isValidPhoneNumber,
} from '@hb/shared'

type AssessmentSearchInterface = AssessmentInterface | ClaimInterface
const removeArrayContainsFilter = <T extends AnyObject>(
  filters: CollectionFilter<T>[] | null | undefined,
): CollectionFilter<T>[] =>
  (filters || []).filter(f => f[1] !== 'array-contains' && f[1] !== 'array-contains-any')
const toAuthReferenceNumberQuery = <
  T extends AssessmentSearchInterface = AssessmentSearchInterface,
>(
  baseQuery: BaseQuery<T>,
  referenceNumber: string,
): BaseQuery<T> => ({
  ...baseQuery,
  filters: [
    ...removeArrayContainsFilter(baseQuery.filters),
    ['authRefNumbers', 'array-contains', referenceNumber],
  ],
})

const toClaimReferenceNumberQuery = <
  T extends AssessmentSearchInterface = AssessmentSearchInterface,
>(
  baseQuery: BaseQuery<T>,
  referenceNumber: string,
): BaseQuery<T> => ({
  ...baseQuery,
  filters: [
    ...removeArrayContainsFilter(baseQuery.filters),
    ['claimRefNumbers', 'array-contains', referenceNumber],
  ],
})

const toEftCheckRefNumberQuery = <T extends AssessmentSearchInterface = AssessmentSearchInterface>(
  baseQuery: BaseQuery<T>,
  referenceNumber: string,
): BaseQuery<T> => ({
  ...baseQuery,
  filters: [
    ...removeArrayContainsFilter(baseQuery.filters),
    ['eftCheckRefNumbers', 'array-contains', referenceNumber],
  ],
})

const toColonCommandQuery = <T extends AssessmentSearchInterface = AssessmentSearchInterface>(
  baseQuery: BaseQuery<T>,
  colonCommand: string,
  colonParam: string,
): BaseQuery<T> | null => {
  if (!colonParam) {
    throw new Error('Colon parameter is required for command: ' + colonCommand)
  }
  switch (colonCommand.toLowerCase()) {
    case 'auth':
      return toAuthReferenceNumberQuery(baseQuery, colonParam.toLowerCase())
    case 'claim':
      return toClaimReferenceNumberQuery(baseQuery, colonParam.toLowerCase())
    case '$':
      return toEftCheckRefNumberQuery(baseQuery, colonParam.toLowerCase())
    default:
      break
  }
  return null
}

// if none of these, search by name
export const toAdminAssessmentSearchQuery = <
  T extends AssessmentSearchInterface = AssessmentSearchInterface,
>(
  baseQuery: BaseQuery<T>,
  search: string,
): BaseQuery<T> | null => {
  // handle colon commands

  const [colonCommand, colonParam] = search.split(':')
  if (colonCommand && colonParam) return toColonCommandQuery(baseQuery, colonCommand, colonParam)

  if (!defaultEmailValidation(search)) {
    return {
      ...baseQuery,
      filters: [...(baseQuery.filters || []), ['email', '==', search]],
    }
  }
  if (isValidPhoneNumber(search)) {
    return {
      ...baseQuery,
      filters: [...(baseQuery.filters || []), ['phone', '==', search.replace(/\D/g, '')]],
    }
  }
  // check if search is a date (DOB)
  if (isValidDate(search)) {
    return {
      ...baseQuery,
      filters: [...(baseQuery.filters || []), ['dobString', '==', search]],
    }
  }

  const trimmed = search.toLowerCase().trim()
  // default to name search
  return {
    ...baseQuery,
    filters: [
      ...removeArrayContainsFilter(baseQuery.filters),
      ['names', 'array-contains', trimmed],
    ],
  }
}
