import {
  AnyObject,
  BaseQuery,
  CollectionFilter,
  CollectionSort,
  LocalCollectionState,
  SortKey,
  UserRoleItem,
} from '@hb/shared'
import { FieldPath } from 'firebase/firestore'
import { Context, createContext, useContext } from 'react'
import { DataListTab } from '../types/data'

export interface DataListContextType<
  T extends AnyObject,
  ExtraColumnProps extends AnyObject = AnyObject,
> {
  baseQuery: BaseQuery<T>
  sort: CollectionSort<T>
  sortBy: (k: SortKey<T>, direction?: 'asc' | 'desc') => void
  toggleSortDirection: () => void
  filterBy: (filter: CollectionFilter<T>) => void
  removeFilter: (filterKey: string | FieldPath) => void
  gridView: boolean
  userFilters: Array<CollectionFilter<T>>
  admins: LocalCollectionState<UserRoleItem>
  tab: DataListTab<T, ExtraColumnProps> | null
}

export const DataListContext = createContext<DataListContextType<any, any>>({
  baseQuery: {
    filters: [],
    collection: '',
    sort: [],
  },
  filterBy: () => {},
  sortBy: () => {},
  removeFilter: () => {},
  toggleSortDirection: () => {},
  admins: {
    error: null,
    items: [],
    loading: false,
  },
  gridView: false,
  userFilters: [],
  tab: null,
  sort: { field: '', order: 'asc' },
})

export const useDataListContext = <T extends AnyObject>() =>
  useContext(DataListContext as Context<DataListContextType<any>>) as DataListContextType<T>
