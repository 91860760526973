import { Box, Button, Center, Collapse, Flex, Text } from '@chakra-ui/react'
import {
  getInvoiceId,
  getInvoiceMonthString,
  getInvoiceScheduleDate,
  getInvoiceSent,
  getMonthStringFromNumber,
  INVOICES,
  ManuallyCreateInvoiceArgs,
  ManuallyCreateInvoiceResponse,
  parseDateFromInvoiceId,
  PracticeInvoice,
  UseQuery,
} from '@hb/shared'
import { collection, CollectionReference, query, where } from 'firebase/firestore'
import { httpsCallable } from 'firebase/functions'
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { db } from '../../backend/db'
import { functions } from '../../backend/functions'
import { practiceSelectField } from '../../collections/fields/practice'
import { PopUpMessageContext } from '../../contexts'
import { useQuery } from '../../hooks/backend/useQuery'
import { SolidActionButton } from '../Buttons'
import { CopyId } from '../CopyId'
import { StandaloneInput } from '../forms/Input'
import { InputRef } from '../forms/Input/types'
import { Loading } from '../Loading'
import { DefaultModal } from '../Modals/DefaultModal'
import { InvoiceStatusView } from './InvoiceStatusView'
import { useInvoicesView } from './InvoicesViewProvider'
import { getInvoicesForMonth } from './utils'

const getCurrentPracticeInvoicesQuery = (practiceId: string | null) => {
  const now = new Date()
  const nextMonth = new Date(now.getTime() + 30 * 24 * 60 * 60 * 1000)
  const lastMonth = new Date(now.getTime() - 30 * 24 * 60 * 60 * 1000)

  const monthStrings = [lastMonth, now, nextMonth].map(getInvoiceMonthString)
  const ids = monthStrings.map(monthString => getInvoiceId(practiceId || 'NONE', monthString, 0))

  const months = ids.map(curr => {
    const { month, year } = parseDateFromInvoiceId(curr)
    return { month, year }
  })

  if (!practiceId)
    return {
      ids,
      months,
      query: null,
    }
  const invoicesRef = collection(db, INVOICES) as CollectionReference<PracticeInvoice>
  return {
    ids,
    months,
    query: query(
      invoicesRef,
      where('practiceId', '==', practiceId),
      where(
        'scheduledForShort',
        'in',
        months.map(({ month, year }) => getInvoiceScheduleDate({ year, month }).split('T')[0]),
      ),
    ),
  }
}

const PracticeInvoicePreview = ({
  month,
  year,
  data,
  isCreating,
  onCreate,
}: {
  month: number
  year: number
  data: UseQuery<PracticeInvoice>
  isCreating: boolean
  onCreate: (isSupplemental: boolean) => void
}) => {
  const { selectInvoice } = useInvoicesView()
  const { error, loading } = data
  const invoices = useMemo(() => getInvoicesForMonth(data, year, month), [data, year, month])
  const unsentInvoice = useMemo(
    () => invoices.find(invoice => !getInvoiceSent(invoice)),
    [invoices],
  )

  const body = useMemo(() => {
    if (invoices.length) {
      return (
        <Flex gap={1.5} minW="0" flexFlow="column" flex={1}>
          {invoices.map(invoice => (
            <Flex key={invoice.id} w="100%" align="center">
              <Button
                px={2}
                flex={1}
                minW="0"
                size="sm"
                h="auto"
                minH="0"
                py={1}
                onClick={() => selectInvoice(invoice.id)}
                border="1px solid #cdcdcd"
                borderRadius={4}
                _hover={{ background: 'blackAlpha.100' }}
                variant="unstyled">
                <InvoiceStatusView invoice={invoice} />
              </Button>
              <Box position="relative" top="16px" width="32px">
                <CopyId item={invoice} />
              </Box>
            </Flex>
          ))}
        </Flex>
      )
    }
    if (error) {
      return <Text color="red.600">Error: {error}</Text>
    }

    return loading ? (
      <Loading text="Loading invoice" />
    ) : (
      <Text w="100%" textAlign="center" fontStyle="italic" color="gray.500" fontSize="sm">
        No invoice for {getMonthStringFromNumber(month + 1, false)}
      </Text>
    )
  }, [error, loading, invoices, month, selectInvoice])
  return (
    <Flex
      flex={1}
      minW="0"
      align="center"
      bg="white"
      boxShadow="md"
      gap={2}
      key={`${month}-${year}`}
      // flexFlow="column"
      p={3}
      w="100%">
      <Text width="70px" whiteSpace="nowrap" fontWeight={600} fontSize="md" color="gray.600">
        {`${getMonthStringFromNumber(month + 1, true)} ${year}`}
      </Text>
      <Flex
        flex={1}
        minW="0"
        pl={2}
        borderLeft="1px solid #cdcdcd"
        borderRight="1px solid #cdcdcd"
        w="100%">
        {body}
      </Flex>
      {!invoices.length && loading ? null : (
        <Center w="140px">
          {unsentInvoice ? (
            <Button
              variant="link"
              onClick={() => selectInvoice(unsentInvoice.id)}
              size="sm"
              fontFamily="Open Sans"
              fontWeight={500}
              color="gray.600">
              Unsent Invoice Exists
            </Button>
          ) : (
            <CreateInvoiceButton
              isCreating={isCreating}
              onCreate={() => onCreate(!!invoices.length)}
            />
          )}
        </Center>
      )}
    </Flex>
  )
}

const PracticeInvoicesPreview = ({
  practiceId,
  onCreate,
  creatingId,
}: {
  practiceId: string | null
  onCreate: (id: string, isSupplemental: boolean) => void
  creatingId: string | null
}) => {
  const {
    query: invoicesQuery,
    months,
    ids,
  } = useMemo(() => getCurrentPracticeInvoicesQuery(practiceId), [practiceId])

  const data = useQuery<PracticeInvoice>(invoicesQuery)

  return (
    <Flex flexFlow="column" p={1} gap={1.5} w="100%">
      {months.map(({ month, year }, idx) => {
        const id = ids[idx]
        return (
          <PracticeInvoicePreview
            key={`${month}-${year}`}
            month={month}
            year={year}
            data={data}
            isCreating={creatingId === id}
            onCreate={isSupplemental => {
              if (id) {
                onCreate(id, isSupplemental)
              }
            }}
          />
        )
      })}
    </Flex>
  )
}

const CreateInvoiceButton = ({
  isCreating,
  onCreate,
}: {
  isCreating: boolean
  onCreate: () => void
}) => (
  <SolidActionButton
    isLoading={isCreating}
    onClick={onCreate}
    isDisabled={isCreating}
    size="xs"
    borderRadius="full">
    <Text>+ Create Invoice</Text>
  </SolidActionButton>
)

const CreateInvoice = () => {
  const { createInvoiceOpen: isOpen } = useInvoicesView()
  const [practiceId, setPracticeId] = useState<string | null>(null)
  const { selectInvoice } = useInvoicesView()
  const [creatingInvoiceId, setCreatingInvoiceId] = useState<string | null>(null)
  const { showMessage } = useContext(PopUpMessageContext)
  const inputRef = useRef<InputRef>(null)
  useEffect(() => {
    if (isOpen && !practiceId) {
      inputRef.current?.focus()
    }
  }, [isOpen, practiceId])
  const onCreate = useCallback(
    async (invoiceId: string, isSupplemental: boolean) => {
      const createFunc = httpsCallable<ManuallyCreateInvoiceArgs, ManuallyCreateInvoiceResponse>(
        functions,
        'manuallyCreateInvoice',
      )

      setCreatingInvoiceId(invoiceId)
      try {
        const {
          data: { createdInvoiceId },
        } = await createFunc({ invoiceId, isSupplemental })
        selectInvoice(createdInvoiceId)
      } catch (e: any) {
        showMessage({
          type: 'error',
          text: 'Error creating invoice',
          subText: e.message,
        })
      }
      setCreatingInvoiceId(null)
    },
    [selectInvoice, showMessage],
  )

  return (
    <Flex w="100%" flexFlow="column">
      <Flex borderBottom="1px solid #cdcdcd" align="center" py={2.5} px={3}>
        <Text flex={1} minW="0" fontFamily="Hero-New" fontSize="lg" fontWeight={500}>
          Create Invoice
        </Text>
      </Flex>
      <Flex bg="gray.100" p={2} w="100%" flexFlow="column">
        <StandaloneInput
          theme="detailed"
          ref={inputRef}
          field={practiceSelectField}
          value={practiceId}
          onChange={setPracticeId}
        />
        <Collapse style={{ width: '100%' }} in={!!practiceId} animateOpacity>
          <PracticeInvoicesPreview
            creatingId={creatingInvoiceId}
            onCreate={onCreate}
            practiceId={practiceId || ''}
          />
        </Collapse>
      </Flex>
    </Flex>
  )
}

export const CreateInvoiceModal = () => {
  const { createInvoiceOpen, setCreateInvoiceOpen } = useInvoicesView()

  return (
    <DefaultModal
      size="2xl"
      isCentered={false}
      isOpen={createInvoiceOpen}
      onClose={() => setCreateInvoiceOpen(false)}
      overlayHeader
      render={() => <CreateInvoice />}
    />
  )
}
