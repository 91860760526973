import { ArrowForwardIcon } from '@chakra-ui/icons'
import {
  Box,
  Flex,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Text,
  Tooltip,
  useDisclosure,
  VStack,
} from '@chakra-ui/react'
import {
  AssessmentInvoiceSnippet,
  capitalizeFirstLetterOfEachWord,
  colors,
  DropdownField,
  FieldTypes,
  getDateString,
  INVOICES,
  PracticeInvoice,
  UNSENT_INVOICE_STATUSES,
  WithId,
} from '@hb/shared'
import { collection, Query, query, where } from 'firebase/firestore'
import React, { useCallback, useContext, useMemo, useState } from 'react'
import { db, manuallyMovePaymentToInvoice } from '../../backend'
import { PopUpMessageContext } from '../../contexts'
import { useArrayFromRecord } from '../../hooks'
import { useQuery } from '../../hooks/backend/useQuery'
import { SolidActionButton } from '../Buttons'
import { StandaloneInput } from '../forms'
import { InvoiceStatusView } from './InvoiceStatusView'

const getUnsentPracticeInvoicesQuery = (practiceId: string) =>
  query(
    collection(db, INVOICES),
    where('practiceId', '==', practiceId),
    where('status', 'in', UNSENT_INVOICE_STATUSES),
    where('archivedOn', '==', null),
  ) as Query<PracticeInvoice>

const useInvoiceField = (
  placeholder: string,
  options: WithId<PracticeInvoice>[],
  invoices: Record<string, PracticeInvoice> | null,
) =>
  useMemo<DropdownField>(
    () => ({
      type: FieldTypes.DROPDOWN,
      placeholder,
      options: options.map(unsentInvoice => ({
        id: unsentInvoice.id,
        text: `${capitalizeFirstLetterOfEachWord(unsentInvoice.status.split('-').join(' '))} - ${
          unsentInvoice.scheduledFor
            ? `Scheduled for ${getDateString(unsentInvoice.scheduledFor, 'short', true)}`
            : `Due on ${getDateString(unsentInvoice.dueDate, 'short', true)}`
        }`,
      })),
      optional: true,
      renderOption: ({ id }) => (
        <Flex flexFlow="column">
          {invoices?.[id] ? <InvoiceStatusView invoice={invoices?.[id]} /> : null}
          <Text fontSize="xs" opacity={0.7}>
            ID: {id}
          </Text>
        </Flex>
      ),
    }),
    [invoices, options, placeholder],
  )

export const MovePaymentPopover = ({
  invoice,
  assessmentId,
  paymentId,
  initialFromId,
  initialToId,
}: {
  invoice: WithId<PracticeInvoice | AssessmentInvoiceSnippet> | null
  assessmentId: string | null | undefined
  paymentId: string
  initialFromId?: string | null
  initialToId?: string | null
}) => {
  const { practiceId } = invoice ?? {}

  const unsentInvoicesQuery = useMemo(
    () => (practiceId ? getUnsentPracticeInvoicesQuery(practiceId) : null),
    [practiceId],
  )

  const { data: unsentInvoices } = useQuery<PracticeInvoice>(unsentInvoicesQuery)

  const [moveToId, setMoveToId] = useState<string | null>(initialToId ?? null)
  const [moveFromId, setMoveFromId] = useState<string | null>(initialFromId ?? null)

  const unsentInvoicesArr = useArrayFromRecord(unsentInvoices ?? {})
  const hasMultipleUnsent = unsentInvoicesArr.length > 1

  const toInvoiceOptions = useMemo(
    () =>
      unsentInvoicesArr.filter(unsentInvoice => {
        if (moveFromId && unsentInvoice.id === moveFromId) return false
        return true
      }),
    [unsentInvoicesArr, moveFromId],
  )

  const fromInvoiceOptions = useMemo(
    () => unsentInvoicesArr.filter(unsentInvoice => unsentInvoice.id !== moveToId),
    [unsentInvoicesArr, moveToId],
  )

  const { isOpen, onOpen, onClose } = useDisclosure()
  const { processResponse, showError } = useContext(PopUpMessageContext)

  const toInvoiceField = useInvoiceField('To Invoice', toInvoiceOptions, unsentInvoices)
  const fromInvoiceField = useInvoiceField('From Invoice', fromInvoiceOptions, unsentInvoices)

  const handleClose = useCallback(() => {
    onClose()
    setMoveToId(null)
  }, [onClose])
  const [moveLoading, setMoveLoading] = useState(false)
  const canMove = useMemo(() => !!moveToId && !!moveFromId, [moveToId, moveFromId])
  const onMove = useCallback(async () => {
    if (!moveToId) return
    if (!moveFromId) return
    if (moveToId === moveFromId) {
      showError('Error moving payment', 'You cannot move a payment to the same invoice')
      return
    }
    const invoiceId = invoice?.id

    if (!invoiceId) return
    if (!assessmentId) {
      processResponse({ error: 'Assessment ID not found' })
      return
    }
    setMoveLoading(true)
    try {
      await manuallyMovePaymentToInvoice({
        assessmentId,
        fromInvoiceId: moveFromId,
        toInvoiceId: moveToId,
        paymentId,
      })
      processResponse({ success: 'Moved payment' })
      handleClose()
    } catch (err: any) {
      console.error(err)
      processResponse({ error: err?.message || 'Error moving payment' })
    }
    setMoveLoading(false)
  }, [
    moveToId,
    invoice,
    assessmentId,
    paymentId,
    processResponse,
    handleClose,
    moveFromId,
    showError,
  ])

  return (
    <Popover
      closeOnBlur={false}
      isOpen={isOpen}
      onClose={handleClose}
      isLazy
      onOpen={onOpen}
      strategy="fixed">
      <PopoverTrigger>
        <Flex>
          <Tooltip
            placement="top"
            hasArrow
            label={`Move ${paymentId === 'deposits' ? 'deposits' : 'payment'} to another invoice`}
            aria-label="Move payment">
            <IconButton
              aria-label="Move payment"
              borderLeftRadius={0}
              size="xs"
              color={colors.green.hex}
              variant="ghost"
              colorScheme="gray"
              icon={<ArrowForwardIcon width={5} height={5} />}
            />
          </Tooltip>
        </Flex>
      </PopoverTrigger>
      <Portal>
        <PopoverContent w="340px">
          <PopoverArrow />
          <PopoverBody p={0}>
            <PopoverCloseButton />
            <Flex p={2} borderBottom="1px solid #e0e0e0" w="100%" justify="space-between">
              <Text lineHeight={1} fontWeight={500} fontSize="md">
                Move Payment
              </Text>
            </Flex>
            <VStack bg="gray.50" px={3} pb={2} position="relative" align="start" spacing={2}>
              {!hasMultipleUnsent ? (
                <Box pt={1}>
                  <Text fontStyle="italic" opacity={0.7} fontSize="md">
                    No other unsent invoices
                  </Text>
                </Box>
              ) : (
                <Flex pt={1} w="100%" flexFlow="column">
                  <Text fontSize="md">From Invoice</Text>
                  <StandaloneInput
                    field={fromInvoiceField}
                    theme="detailed"
                    value={moveFromId}
                    onChange={setMoveFromId}
                  />
                  <Text fontSize="md">To Invoice</Text>
                  <StandaloneInput
                    field={toInvoiceField}
                    theme="detailed"
                    value={moveToId}
                    onChange={setMoveToId}
                  />
                  <SolidActionButton
                    isLoading={moveLoading}
                    onClick={onMove}
                    opacity={canMove ? 1 : 0.7}
                    ml="auto"
                    gap={1}
                    isDisabled={!canMove}
                    size="sm">
                    Move Payment
                  </SolidActionButton>
                </Flex>
              )}
            </VStack>
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  )
}
