import {
  adminRoles,
  getPracticeUsersCollectionPath,
  INVOICES,
  PracticeInvoice,
  PracticeUserRoleItem,
} from '@hb/shared'
import { collection, Firestore, query, Query, where } from 'firebase/firestore'

export const getPracticeAdminsQuery = (db: Firestore, practiceId: string) =>
  query(
    collection(db, getPracticeUsersCollectionPath(practiceId)),
    where('role', 'in', adminRoles),
  ) as Query<PracticeUserRoleItem>

export const getPracticeInvoicesQuery = (db: Firestore, practiceId: string) =>
  query(collection(db, INVOICES), where('practiceId', '==', practiceId)) as Query<PracticeInvoice>

export const getPracticeMonthInvoicesQuery = (
  db: Firestore,
  practiceId: string,
  year: number,
  month: number,
) =>
  query(
    collection(db, INVOICES),
    where('practiceId', '==', practiceId),
    where('scheduledForShort', '==', `${year}-${month}-01`),
  ) as Query<PracticeInvoice>
