import { InfoIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  Flex,
  HStack,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Text,
  Tooltip,
  VStack,
} from '@chakra-ui/react'
import {
  AssessmentInvoiceSnippet,
  capitalizeFirstLetter,
  ChargedPaymentReceived,
  colors,
  formatDollarValue,
  formatPaymentType,
  getDateString,
  getInvoiceSent,
  PracticeInvoice,
  PracticeInvoiceLineItem,
  separatePayments,
  UpdateCallback,
  WithId,
} from '@hb/shared'

import React, { useCallback, useMemo } from 'react'
import {
  addLineItemToAssessmentInvoice,
  removeLineItemFromAssessmentInvoice,
} from '../../../backend'
import { useApp } from '../../../contexts'
import { useAssessmentInvoiceSnippets } from '../../../hooks'
import { getRowBackground } from '../../DataView'
import { Expandable } from '../../Expandable'
import { FeeBadge } from '../FeeBadge'
import { IndexCircle } from '../IndexCircle'
import { useInvoicesView } from '../InvoicesViewProvider'
import { EditableLineItemView } from '../LineItems/EditableLineItemView'
import { LineItemPopover } from '../LineItems/LineItemPopover'
import { MovePaymentPopover } from '../MovePaymentPopover'
import { InvoicePaymentHistory } from '../PaymentHistory'
import { CustomFeePercentPopover } from './CustomFeePercentPopover'
import { PaymentOmissionEdit } from './PaymentOmission'

const ReceivedPaymentView = ({
  paymentId,
  assessmentId,
  invoice,
  assessmentInvoiceSnippets,
}: {
  assessmentId: string
  paymentId: string
  invoice: WithId<PracticeInvoice>
  assessmentInvoiceSnippets: Record<string, AssessmentInvoiceSnippet>
}) => {
  const isSent = useMemo(() => getInvoiceSent(invoice), [invoice])
  const { appName } = useApp()
  const assessmentSnippet = invoice.assessments[assessmentId]
  const payment = assessmentSnippet?.chargedPayments[paymentId]
  const omission = invoice.omissions?.[`${assessmentId}-${paymentId}`]
  const description = useMemo(
    () =>
      `${capitalizeFirstLetter(formatPaymentType(payment.type))} paid by ${
        payment.paidBy
      } on ${getDateString(payment.date, 'short')}${payment.previousFeeAmount ? ' (partial)' : ''}`,
    [payment],
  )

  return (
    <HStack
      spacing={2}
      py={1}
      px={3}
      sx={{
        ':nth-of-type(odd)': { background: 'rgb(245,245,245)' },
        ':nth-of-type(even)': { background: 'white' },
      }}
      w="100%">
      <HStack flex={1}>
        <Popover isLazy placement="right" trigger="hover" strategy="fixed">
          <PopoverTrigger>
            <Button
              minW="0"
              variant="link"
              fontFamily="Open Sans"
              isTruncated
              fontWeight={600}
              color="gray.500"
              size="sm">
              {description}
            </Button>
          </PopoverTrigger>
          <PopoverContent bg="gray.50">
            <PopoverBody>
              <PopoverArrow bg="gray.50" />
              <InvoicePaymentHistory
                assessmentInvoiceSnippets={assessmentInvoiceSnippets}
                paymentId={paymentId}
                thisInvoiceId={invoice.id}
              />
            </PopoverBody>
          </PopoverContent>
        </Popover>
        <Flex align="center" ml="auto">
          {payment.notes ? (
            <Tooltip
              placement="top"
              hasArrow
              bg={colors.green.hex}
              color="gray.50"
              label={payment.notes}>
              <IconButton
                size="xs"
                variant="ghost"
                icon={<InfoIcon color="gray.500" />}
                aria-label="notes"
              />
            </Tooltip>
          ) : null}
          <PaymentOmissionEdit
            omission={omission}
            assessmentId={assessmentId}
            invoiceId={invoice.id}
            paymentId={paymentId}
          />
          {isSent || appName === 'providers-app' ? null : (
            <Box ml="auto">
              <MovePaymentPopover
                assessmentId={assessmentId}
                initialFromId={invoice.id}
                paymentId={paymentId}
                invoice={invoice}
              />
            </Box>
          )}
        </Flex>
      </HStack>
      <HStack width="40px" justify="center" position="relative" spacing={1}>
        <CustomFeePercentPopover
          assessmentId={assessmentId}
          paymentId={paymentId}
          invoice={invoice}
        />
      </HStack>
      <FeeBadge
        amount={payment.amount}
        width="120px"
        opacity={payment.invoicedAmount < payment.amount ? 0.65 : 1}
        color="gray.500"
        fontWeight={600}
      />
      <HStack width="80px" justify="center" position="relative" spacing={1}>
        {/* {index ? (
          <IncrementBadge amount={listedFeePercent * 0.01 * payment.amount} />
        ) : null} */}
        <FeeBadge amount={payment.feeAmount} />
      </HStack>
      <Box w="8px" />
    </HStack>
  )
}

const DepositsNotesPopover = ({
  deposits,
}: {
  deposits: Record<string, ChargedPaymentReceived>
}) => (
  <Popover placement="top" strategy="fixed" trigger="hover">
    <PopoverTrigger>
      <IconButton
        size="xs"
        variant="ghost"
        icon={<InfoIcon color="gray.500" />}
        aria-label="notes"
      />
    </PopoverTrigger>
    <Portal>
      <PopoverContent>
        <PopoverArrow />
        <VStack spacing={1} p={2} align="flex-start">
          {Object.entries(deposits).map(([depositId, deposit]) => (
            <VStack spacing={0} key={depositId} w="100%" align="flex-start">
              <Text fontSize="sm" color="gray.600">
                {getDateString(deposit.date, 'short')} - {formatDollarValue(deposit.amount)}
              </Text>
              <Text fontSize="sm" fontFamily="Hero-New">
                {deposit.notes || 'No notes'}
              </Text>
            </VStack>
          ))}
        </VStack>
      </PopoverContent>
    </Portal>
  </Popover>
)

const InvoiceAssessmentDeposits = ({
  assessmentId,
  invoice,
}: {
  assessmentId: string
  invoice: WithId<PracticeInvoice>
}) => {
  const { omissions } = invoice ?? {}
  const { appName } = useApp()
  const { chargedPayments } = invoice.assessments[assessmentId] ?? {}
  const deposits = useMemo(
    () => separatePayments(chargedPayments ?? {}).deposits,
    [chargedPayments],
  )
  const receivedDepositsTotal = useMemo(
    () => Object.values(deposits).reduce((total, deposit) => total + deposit.invoicedAmount, 0),
    [deposits],
  )
  const fee = useMemo(
    () => Object.values(deposits).reduce((total, deposit) => total + deposit.feeAmount, 0),
    [deposits],
  )
  const description = useMemo(
    () =>
      `Deposits received on ${Object.values(deposits)
        .map(d => getDateString(d.date, 'short'))
        .join(', ')}`,
    [deposits],
  )

  const omission = omissions?.[`${assessmentId}-deposits`]

  return (
    <HStack
      spacing={2}
      py={1}
      px={3}
      sx={{
        ':nth-of-type(odd)': { background: 'rgb(245,245,245)' },
        ':nth-of-type(even)': { background: 'white' },
      }}
      w="100%">
      <HStack minW="auto" flex={1}>
        <Tooltip placement="top" hasArrow label={description}>
          <Text
            minW="0"
            flex={1}
            maxW="400px"
            fontFamily="Open Sans"
            isTruncated
            fontWeight={600}
            color="gray.500"
            fontSize="sm">
            {description}
            {receivedDepositsTotal ? '' : ' (PRM offset)'}
          </Text>
        </Tooltip>
        <Flex align="center" ml="auto">
          <DepositsNotesPopover deposits={deposits} />
          <PaymentOmissionEdit
            omission={omission}
            invoiceId={invoice.id}
            assessmentId={assessmentId}
            paymentId="deposits"
          />
          {appName === 'providers-app' ? null : (
            <MovePaymentPopover
              assessmentId={assessmentId}
              paymentId="deposits"
              invoice={invoice}
            />
          )}
        </Flex>
      </HStack>
      <HStack width="40px" justify="center" position="relative" spacing={1}>
        <CustomFeePercentPopover
          assessmentId={assessmentId}
          invoice={invoice}
          paymentId="deposits"
        />
      </HStack>
      <FeeBadge amount={receivedDepositsTotal} width="120px" color="gray.500" />
      <HStack width="80px" justify="center" position="relative" spacing={1}>
        {/* {index ? (
        <IncrementBadge amount={listedFeePercent * 0.01 * payment.amount} />
      ) : null} */}
        <FeeBadge amount={fee} />
      </HStack>
      <Box w="8px" />
    </HStack>
  )
}

const InvoiceAssessmentChargedPayments = ({
  assessmentId,
  invoice,
}: {
  assessmentId: string
  invoice: WithId<PracticeInvoice>
}) => {
  const assessmentSnippet = invoice.assessments[assessmentId]
  const { deposits, homeBirthRetainers, retainers, other } = useMemo(
    () => separatePayments(assessmentSnippet?.chargedPayments),
    [assessmentSnippet?.chargedPayments],
  )

  const additionalLineItems = invoice.additionalLineItems?.[assessmentId]

  const addLineItem = useCallback(
    async (value: PracticeInvoiceLineItem, lineItemId?: string): Promise<UpdateCallback> => {
      await addLineItemToAssessmentInvoice({
        ...value,
        invoiceId: invoice.id,
        assessmentId,
        lineItemId: lineItemId ?? null,
        additionalPatientId: null,
      })
      return { success: 'Added line item' }
    },
    [assessmentId, invoice],
  )

  const removeLineItem = useCallback(
    async (lineItemId: string): Promise<UpdateCallback> => {
      await removeLineItemFromAssessmentInvoice({
        invoiceId: invoice.id,
        assessmentId,
        lineItemId,
        additionalPatientId: null,
      })
      return { success: 'Removed line item' }
    },
    [assessmentId, invoice],
  )

  const sortedLineItems = useMemo(() => {
    if (!additionalLineItems) return []
    return Object.entries(additionalLineItems).sort(([, a], [, b]) => {
      if (a.createdAt > b.createdAt) return -1
      if (a.createdAt < b.createdAt) return 1
      return 0
    })
  }, [additionalLineItems])

  const totalPaymentNumber = useMemo(
    () =>
      Object.keys(retainers).length +
      Object.keys(deposits).length +
      Object.keys(homeBirthRetainers).length +
      Object.keys(other).length +
      sortedLineItems.length,
    [retainers, deposits, homeBirthRetainers, other, sortedLineItems],
  )

  const { data: assessmentInvoiceSnippets } = useAssessmentInvoiceSnippets(assessmentId)

  return (
    <VStack px={2} align="flex-start" w="100%" spacing={0}>
      {Object.keys(deposits).length ? (
        <InvoiceAssessmentDeposits assessmentId={assessmentId} invoice={invoice} />
      ) : null}
      {Object.entries(retainers).map(([paymentId]) => (
        <ReceivedPaymentView
          invoice={invoice}
          paymentId={paymentId}
          assessmentInvoiceSnippets={assessmentInvoiceSnippets}
          assessmentId={assessmentId}
          key={paymentId}
        />
      ))}
      {Object.entries(homeBirthRetainers).map(([paymentId]) => (
        <ReceivedPaymentView
          invoice={invoice}
          paymentId={paymentId}
          assessmentInvoiceSnippets={assessmentInvoiceSnippets}
          assessmentId={assessmentId}
          key={paymentId}
        />
      ))}
      {Object.entries(other).map(([paymentId]) => (
        <ReceivedPaymentView
          invoice={invoice}
          paymentId={paymentId}
          assessmentInvoiceSnippets={assessmentInvoiceSnippets}
          assessmentId={assessmentId}
          key={paymentId}
        />
      ))}
      {sortedLineItems.length
        ? sortedLineItems.map(([lineItemId, lineItem]) => (
            <EditableLineItemView
              onDelete={() => removeLineItem(lineItemId)}
              onSubmit={v => addLineItem(v, lineItemId)}
              key={lineItemId}
              id={lineItemId}
              value={lineItem}
            />
          ))
        : null}
      <LineItemPopover onSubmit={addLineItem} />
      {totalPaymentNumber === 0 ? (
        <Text w="100%" bg="gray.50" px={2} py={1} color="gray.500">
          No payments received
        </Text>
      ) : null}
    </VStack>
  )
}

export const InvoiceAssessmentBreakdown = ({
  assessmentId,
  invoice,
  index,
}: {
  assessmentId: string
  invoice: WithId<PracticeInvoice>
  index: number
}) => {
  const { selectPregnancy } = useInvoicesView()
  const assessment = useMemo(() => invoice.assessments[assessmentId], [invoice, assessmentId])
  const paid = useMemo(() => assessment.receivedAmount >= assessment.dueAmount, [assessment])
  const statusColor = useMemo(() => {
    if (paid) return 'gray.500'
    return 'orange.500'
  }, [paid])

  const hasAmount = useMemo(() => assessment.practiceFee > 0.005, [assessment])

  return (
    <Expandable
      bg="gray.200"
      headerProps={{ bg: getRowBackground(index) }}
      borderBottom="1px solid #cdcdcd"
      header={({ isOpen }) => (
        <HStack
          opacity={hasAmount || isOpen ? 1 : 0.7}
          transition="opacity 300ms"
          spacing={2}
          py={2}
          px={2}
          w="100%">
          {/* {assessment.receivedAmount === 0 ? (
            <WarningTwoIcon width={5} height={5} color="yellow.500" />
          ) : (
            <IndexCircle index={index} />
          )} */}
          <IndexCircle index={index} />
          <Flex flex={1}>
            <Tooltip placement="top" hasArrow bg="gray.600" label="Click to open assessment">
              <Button
                minW="0"
                variant="link"
                position="relative"
                top="1px"
                // lineHeight={1}
                fontFamily="Comfortaa"
                onClick={e => {
                  e.stopPropagation()
                  selectPregnancy(invoice.id, assessment.assessmentId, 'payments')
                }}
                fontSize="sm"
                borderRadius={0}
                color="#666"
                fontWeight={600}
                isTruncated>
                {assessment.lname}, {assessment.fname}
              </Button>
            </Tooltip>
          </Flex>
          <HStack width="120px" spacing={1}>
            <Tooltip
              label={paid ? 'PAID' : 'PARTIAL'}
              fontWeight={600}
              bg={paid ? 'green.500' : 'gray.500'}
              hasArrow
              placement="top">
              <FeeBadge amount={assessment.chargedAmount} color={statusColor} />
            </Tooltip>
          </HStack>
          <HStack width="80px" spacing={0}>
            <FeeBadge amount={assessment.practiceFee} />
          </HStack>
        </HStack>
      )}>
      <InvoiceAssessmentChargedPayments assessmentId={assessmentId} invoice={invoice} />
    </Expandable>
  )
}
