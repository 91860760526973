import { CloseIcon, SearchIcon } from '@chakra-ui/icons'
import {
  Badge,
  Box,
  Button,
  Center,
  Checkbox,
  Flex,
  HStack,
  IconButton,
  Image,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
  VStack,
} from '@chakra-ui/react'
import {
  AnyObject,
  BasePresetFilter,
  CollectionFilter,
  colors,
  DropdownField,
  DropdownPresetFilter,
  FieldTypes,
  IdField,
  isDropdownPresetFilter,
  PresetFilter,
} from '@hb/shared'
import React, { RefObject, useCallback, useContext, useMemo, useRef } from 'react'
import { DataListContext, ScreenContext, useDataListContext } from '../../contexts'
import filterIcon from '../../icons/tune_green.svg'

import { useCollectionItem } from '../../hooks'
import { StandaloneInput } from '../forms/Input'
import { InputRef } from '../forms/Input/types'
import { SortButton } from './SortButton'

const PresetFilterButton = <T extends AnyObject>({
  filter,
  filters,
  toggleFilter,
  isChild,
}: {
  filter: BasePresetFilter
  filters: CollectionFilter<T>[]
  toggleFilter: (toggled: CollectionFilter<T>) => void
  isChild?: boolean
}) => {
  const { label } = filter
  const isActive = filters.some(f => f === filter.filter)
  return (
    // <Button
    //   borderRadius="full"
    //   w='100%'
    //   size="xs"
    //   fontWeight={500}
    //   fontFamily="Hero-New"
    //   onClick={() => (isActive ? removeFilter(filter.filter[0]) : filterBy(filter.filter))
    //   }
    //   bg={isActive ? colors.green.hex : 'gray.200'}
    //   color={isActive ? 'white' : 'gray.800'}
    //   _hover={{ bg: isActive ? 'gray.300' : 'green.300' }}
    // >
    //   {label}
    // </Button>
    <VStack w="100%" spacing={0} align="flex-end">
      {isChild ? null : (
        <Text fontSize="sm" color="gray.500" fontWeight={600}>
          {label}
        </Text>
      )}
      <Checkbox
        onChange={() => toggleFilter(filter.filter)}
        colorScheme="green"
        isChecked={isActive}
        textAlign="right"
        gap={2}
        color="gray.500"
        flexFlow="row-reverse">
        {label}
      </Checkbox>
    </VStack>
  )
}

const DropdownFilterButton = <T extends AnyObject>({
  filter,
  filters,
  toggleFilter,
}: {
  filter: DropdownPresetFilter
  filters: CollectionFilter<T>[]
  toggleFilter: (toggled: CollectionFilter<T>) => void
}) => {
  const { label, searchable } = filter
  const selectedOption = useMemo(
    () => filter.filters.find(f => filters.includes(f.filter)),
    [filters, filter.filters],
  )

  const field = useMemo<DropdownField>(
    () => ({
      placeholder: label,
      searchable: !!filter.searchable,
      optional: true,
      type: FieldTypes.DROPDOWN,
      options: filter.filters.map(f => ({ text: f.label, id: f.label })),
    }),
    [filter, label],
  )

  return (
    <VStack alignItems="flex-end" spacing={0} w="100%">
      <Text fontSize="sm" color="gray.500" fontWeight={600}>
        {label}
      </Text>
      {searchable ? (
        <Box maxW="160px">
          <StandaloneInput
            value={selectedOption?.label}
            theme="detailed"
            style={{ padding: '0.25rem 0.5rem', fontSize: '0.9rem' }}
            field={field}
            onChange={value => {
              if (selectedOption && value && selectedOption.label !== value.label)
                toggleFilter(selectedOption.filter)
              if (!value && selectedOption) toggleFilter(selectedOption.filter)
              const selectedFilter = filter.filters.find(f => f.label === value)
              if (selectedFilter) {
                toggleFilter(selectedFilter.filter)
              }
            }}
          />
        </Box>
      ) : (
        <>
          <VStack spacing={0} alignItems="flex-end">
            {filter.filters.map(f => (
              <PresetFilterButton
                filters={filters}
                toggleFilter={toggleFilter}
                key={f.filter.join('-')}
                isChild
                filter={f}
              />
            ))}
          </VStack>
        </>
      )}
    </VStack>
  )
}

const FilterButton = <T extends AnyObject>({
  filter,
  filters,
  toggleFilter,
}: {
  filter: PresetFilter
  filters: CollectionFilter<T>[]
  toggleFilter: (toggled: CollectionFilter<T>) => void
}) =>
  isDropdownPresetFilter(filter) ? (
    <DropdownFilterButton filters={filters} toggleFilter={toggleFilter} filter={filter} />
  ) : (
    <PresetFilterButton filters={filters} toggleFilter={toggleFilter} filter={filter} />
  )

export const PresetFilters = <T extends AnyObject>({
  presetFilters,
  toggleFilter,
  currentFilters,
}: {
  presetFilters: PresetFilter[]
  toggleFilter: (filter: CollectionFilter<T>) => void
  currentFilters: CollectionFilter<T>[]
}) => {
  const { isMobile } = useContext(ScreenContext)
  const activePresetFilters = useMemo(() => {
    if (!presetFilters) return []
    return presetFilters.reduce<{ text: string; filter: CollectionFilter<T> }[]>((acc, filter) => {
      if (isDropdownPresetFilter(filter)) {
        const selectedOption = filter.filters.find(f => currentFilters.includes(f.filter))
        if (selectedOption) {
          acc.push({
            text: selectedOption.label,
            filter: selectedOption.filter,
          })
        }
      } else if (currentFilters.includes(filter.filter)) {
        acc.push({ text: filter.label, filter: filter.filter })
      }
      return acc
    }, [])
  }, [currentFilters, presetFilters])
  return presetFilters?.length ? (
    <HStack spacing={1}>
      {activePresetFilters.map((filter, idx) => (
        <Badge
          py="0.25rem"
          px={2}
          borderRadius="full"
          bg={colors.green.hex}
          color="white"
          fontFamily="Open Sans"
          fontWeight={600}
          fontSize="xs"
          key={`${idx}-${filter.filter?.join('-')}`}>
          <HStack spacing={1}>
            <Text lineHeight={1}>{filter.text}</Text>
            <IconButton
              size="xs"
              width="16px"
              height="16px"
              minW="0"
              variant="unstyled"
              bg="transparent"
              color="gray.100"
              borderRadius="full"
              aria-label="Remove filter"
              icon={
                <Center>
                  <CloseIcon width="9px" />
                </Center>
              }
              onClick={() => toggleFilter(filter.filter)}
            />
          </HStack>
        </Badge>
      ))}
      <Popover
        closeOnBlur={false}
        placement="left-start"
        trigger={isMobile ? 'click' : 'hover'}
        strategy="fixed">
        <PopoverTrigger>
          <IconButton
            size="sm"
            variant="ghost"
            aria-label="Filters"
            icon={
              <Image
                width="20px"
                filter={`grayscale(${currentFilters.length ? 0 : 100}%)`}
                src={filterIcon}
              />
            }
          />
        </PopoverTrigger>
        <PopoverContent borderRadius={6} w="220px" bg="gray.50">
          <PopoverArrow bg="gray.50" />
          <VStack spacing={2} w="100%" py={2} px={3}>
            <HStack justify="center" w="100%" borderBottom="1px solid #cdcdcd">
              <Text fontSize="sm" color="gray.500" fontWeight={600}>
                FILTERS
              </Text>
            </HStack>
            {presetFilters.map((filter, idx) => (
              <FilterButton
                toggleFilter={toggleFilter}
                filters={currentFilters}
                key={`${idx}-${filter.filter?.join('-')}`}
                filter={filter}
              />
            ))}
          </VStack>
        </PopoverContent>
      </Popover>
    </HStack>
  ) : null
}
export const DataListPresetFilters = () => {
  const { tab, removeFilter, userFilters, filterBy } = useContext(DataListContext)
  const { presetFilters } = tab ?? {}

  return (
    <PresetFilters
      presetFilters={presetFilters && Array.isArray(presetFilters) ? presetFilters : []}
      toggleFilter={filter =>
        userFilters.includes(filter) ? removeFilter(filter[0]) : filterBy(filter)
      }
      currentFilters={userFilters}
    />
  )
}

export const IdFieldSelectFilter = <T extends AnyObject>({
  propName,
  sortPropName,
  value,
  field,
  inputRef,
}: {
  propName: string
  sortPropName: string
  value: string | null
  field: IdField<T>
  inputRef: RefObject<InputRef>
}) => {
  const { filterBy, removeFilter, sort, tab, sortBy } = useDataListContext()

  const handleChange = useCallback(
    (updatedId: string | null) => {
      if (!updatedId) {
        removeFilter(propName)
        return
      }
      filterBy([propName, '==', updatedId === 'NULL' ? null : updatedId])
      if (tab && sortPropName === sort.field) {
        sortBy(tab?.defaultSort.field, tab?.defaultSort.order)
      }
    },
    [filterBy, removeFilter, propName, sort, sortBy, tab, sortPropName],
  )

  const fieldWithNoneOption = useMemo(
    () => ({
      ...field,
      withNoneOption: true,
    }),
    [field],
  )
  return (
    <StandaloneInput
      theme="detailed"
      value={value}
      ref={inputRef}
      field={fieldWithNoneOption}
      onChange={handleChange}
    />
  )
}

export const IdFieldSelectHeader = <T extends AnyObject>({
  propName,
  sortPropName,
  defaultDir,
  field,
  label,
}: {
  propName: string
  sortPropName: string
  defaultDir: 'asc' | 'desc'
  field: IdField<T>
  label: string
}) => {
  const { userFilters } = useDataListContext()
  const value = useMemo(() => {
    const propFilter = userFilters.find(f => f[0] === propName)
    if (!propFilter) return undefined
    const itemId = propFilter[2]
    return itemId
  }, [userFilters, propName])

  const inputRef = useRef<InputRef>(null)
  const { item } = useCollectionItem(field.collection, value)
  return (
    <Box w="100%" px={1}>
      <Flex maxW="100%" align="center">
        <Popover initialFocusRef={inputRef} strategy="fixed">
          <PopoverTrigger>
            <Button minW="22px" size="sm" variant="link" maxW="100%">
              <HStack maxW="100%" spacing={1}>
                <SearchIcon />
                {value !== undefined ? (
                  <Text
                    flex={1}
                    textAlign="left"
                    minW="0"
                    isTruncated
                    fontSize="sm"
                    color="gray.500"
                    fontWeight={600}>
                    {item ? item.name : 'None'}
                  </Text>
                ) : null}
              </HStack>
            </Button>
          </PopoverTrigger>
          <PopoverContent borderRadius={6} w="300px" bg="gray.50">
            <PopoverBody>
              <VStack spacing={2} w="100%">
                <IdFieldSelectFilter
                  value={value === null ? 'NULL' : value}
                  propName={propName}
                  inputRef={inputRef}
                  sortPropName={sortPropName}
                  field={field}
                />
              </VStack>
            </PopoverBody>
            <PopoverArrow bg="gray.50" />
          </PopoverContent>
        </Popover>
        {value !== undefined ? null : (
          <SortButton defaultDir={defaultDir} sortKey={sortPropName}>
            {label}
          </SortButton>
        )}
      </Flex>
    </Box>
  )
}
